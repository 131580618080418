<template>
	<nav id="desktopNav" class="mobileInactive">
		<ul>
			<!-- <img src="../assets/logo.png" alt="" width="50"> -->
			<a href="/" style="font-size: 25px; color: #f6f6f6;"><b>A2Motion</b></a>
			<div style="display: flex; justify-content: flex-start; align-items: center;">
				<li class="list active">
					<a href="#home">
						Accueil
					</a>
				</li>
				<li class="list">
					<a href="#galery">
						Galeries
					</a>
				</li>
				<li class="list">
					<a href="#contact">
						Contact
					</a>
				</li>
			</div>
		</ul>
		<div style="display: flex; justify-content: space-evenly; align-items: center; width: 200px;">
			<a href="https://instagram.com/axel.alezra?igshid=MzRlODBiNWFlZA==">
				<img src="../assets/icons8-instagram-96.png" alt="instagram" width="35">
			</a>
			<a href="https://youtube.com/@axelalezra3737">
				<img src="../assets/icons8-youtube-96.png" alt="youtube" width="35">
			</a>
			<a href="https://www.tiktok.com/@axel.alezra?_t=8cOb5Au33LH&_r=1">
				<img src="../assets/icons8-tiktok-96.png" alt="tiktok" width="35">
			</a>
		</div>
	</nav>
	<nav id="mobileNav" class="desktopInactive">
		<a href="/" style="font-size: 25px; color: #f6f6f6; text-decoration: none; margin-left: 30px;"><b>A2Motion</b></a>
		<div id="burger-container">
			<div class="burger" v-if="!isMenuOpen">
				<div class="line"></div>
				<div class="line"></div>
				<div class="line"></div>
			</div>
			<div class="openedBurger" v-else>
				<div id="cross-line1"></div>
				<div id="cross-line2"></div>
			</div>
		</div>
	</nav>
	<ul v-if="isMenuOpen" id="openedMenu">
		<!-- <img src="../assets/logo.png" alt="" width="50"> -->
		<div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%;;">
			<li class="list mobile-menu-element" @click="isMenuOpen = !isMenuOpen">
				<a href="#home">
					Accueil
				</a>
			</li>
			<li class="list mobile-menu-element" @click="isMenuOpen = !isMenuOpen">
				<a href="#galery">
					Galeries
				</a>
			</li>
			<li class="list mobile-menu-element" @click="isMenuOpen = !isMenuOpen">
				<a href="#contact">
					Contact
				</a>
			</li>
			<div style="display: flex; justify-content: space-evenly; align-items: center; width: 100%;margin-top: 50px;">
				<a href="https://instagram.com/axel.alezra?igshid=MzRlODBiNWFlZA==">
					<img src="../assets/icons8-instagram-96.png" alt="instagram" width="60">
				</a>
				<a href="https://youtube.com/@axelalezra3737">
					<img src="../assets/icons8-youtube-96.png" alt="youtube" width="60">
				</a>
				<a href="https://www.tiktok.com/@axel.alezra?_t=8cOb5Au33LH&_r=1">
					<img src="../assets/icons8-tiktok-96.png" alt="tiktok" width="60">
				</a>
			</div>
		</div>
	</ul>
	<section class="sec" id="home">
		<div class="container">
			<div class="row" style="margin-bottom: 200px;">
				<div class="col-12 col-md-4"></div>
				<div class="col-12 col-md-4">
					<div style="display: flex; justify-content: center; align-items: center;">
						<img src="../assets/logo.png" alt="" width="125">
						<h1 style="color: white; font-size:  75px;">Motion</h1>
					</div>
					<!-- <AnimateTitle /> -->
					<div style="display: flex; justify-content: center; align-items: center; margin: 35px 0;">
						<p>Nous sommes</p>
						<div style="text-align: left; margin-left: 5px; line-height: 15px; font-weight: bold;">
							<p style="color: #515151;">
								Vidéastes
							</p>
							<p style="color: #515151;">
								Photographs
							</p>
							<p style="color: yellow;">
								réalisateurs
							</p>
							<p style="color: #515151;">
								Scénaristes
							</p>
							<p style="color: #515151;">
								Monteurs
							</p>
						</div>
					</div>
					<div id="btn-container">
						<a href="#galery"><button class="main-btn">Découvrir</button></a>
						<a href="#contact"><button class="main-btn">Nous contacter</button></a>
					</div>
				</div>
				<div class="col-12 col-md-4"></div>
			</div>
		</div>
		<!-- <video autoplay loop muted playsinline controls style="width: 100%;">
			<source src="../assets/bateau-bg42.mp4" type="video/mp4">
		</video> -->
		<div>
			<div id="carouselExampleDark" class="carousel carousel-dark slide">
				<div class="carousel-indicators">
					<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active"
						aria-current="true" aria-label="Slide 1"></button>
					<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
						aria-label="Slide 2"></button>
					<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2"
						aria-label="Slide 3"></button>
				</div>
				<div class="carousel-inner">
					<div class="carousel-item active" data-bs-interval="10000">
						<video autoplay loop muted playsinline style="width: 100%;">
							<source src="../assets/bateau-bg42.mp4" type="video/mp4">
						</video>
					</div>
					<div class="carousel-item" data-bs-interval="2000">
						<video autoplay loop muted playsinline style="width: 100%;">
							<source src="../assets/bateau-bg42.mp4" type="video/mp4">
						</video>
					</div>
					<div class="carousel-item">
						<video autoplay loop muted playsinline style="width: 100%;">
							<source src="../assets/bateau-bg42.mp4" type="video/mp4">
						</video>
					</div>
				</div>
				<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
					data-bs-slide="prev">
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Previous</span>
				</button>
				<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
					data-bs-slide="next">
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Next</span>
				</button>
			</div>
		</div>
	</section>
	<section class="sec" id="galery">
		<div class="container">
			<div style="text-align: left; padding: 75px 0; line-height: 0;">
				<p>Particulier ou profesionnel</p>
				<h2 style="font-size: 50px;"><b>La Galerie</b></h2>
			</div>
			<div class="row">
				<div v-for="(el, name, index) in galery_list" :key="index" class="col-12 col-md-6 col-lg-4">
					<p style="text-align: left; text-transform: capitalize;">{{ el["title"] }}</p>
					<iframe class="video" v-bind:src="el['url']" title="YouTube video player" frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen></iframe>
				</div>
			</div>
		</div>
	</section>
	<section class="sec" id="contact">
		<div class="container">
			<h2 style="font-size: 50px; text-align: left;"><b>Contact</b></h2>
			<div class="row">
				<div class="col-12 col-md-2 col-lg-4"></div>
				<form ref="form" @submit.prevent="sendEmail" class="col-12 col-md-8 col-lg-4">
					<input type="email" placeholder="Email" name="email" id="email" required>
					<textarea name="message" placeholder="Message" id="massage" rows="10" required></textarea>
					<input type="submit" value="Envoyer" id="submit">
				</form>
				<div class="col-12 col-md-2 col-lg-4"></div>
			</div>
		</div>
	</section>
	<div id="footer">
		<img src="../assets/logo.png" width="200" alt="Logo A2Motion" style="position: absolute; z-index: 5; opacity: 10%;">
		<div id="content">
			<p style="font-weight: 900; color: #1a1a1a; font-size: 30px;"><b>A2Motion</b></p>
			<div style="display: flex; justify-content: space-evenly; align-items: center;">
				<span>Vidéaste</span>
				<span>Photographe</span>
				<span>Réalisateur</span>
				<span>Scénariste</span>
				<span>Monteur</span>
			</div>
		</div>
	</div>
</template>

<script>
import { getDatabase, ref, get, child } from "firebase/database";
// import AnimateTitle from "@/components/AnimateTitle.vue"
import emailjs from '@emailjs/browser';

export default {
	name: 'HomeView',
	components: {
		// AnimateTitle
	},
	data() {
		return {
			galery_list: {},
			isMenuOpen: false
		}
	},
	methods: {
		getGaleryList() {
			const dbRef = ref(getDatabase());
			get(child(dbRef, `galery_list`)).then((snapshot) => {
				if (snapshot.exists()) {
					const galeryData = snapshot.val();
					const jsonData = JSON.parse(JSON.stringify(galeryData))
					this.galery_list = jsonData
					console.log(this.galery_list)
				} else {
					console.log("There is no data available");
					this.alcoolSelected = ''
				}
			}).catch((error) => {
				console.error(error);
			});
		},
		reset() {
			document.getElementById("email").value = ''
			document.getElementById("message").value = ''
		},
		sendEmail() {
			this.isLoading = true
			emailjs.sendForm('service_2xsdwqn', 'template_xosb04t', this.$refs.form, '4ZNm0tOlf6hQzxNji')
				.then(() => {
					this.isLoading = false
					alert("Message send")
					// this.reset()
				}, () => {
					this.isLoading = false
					alert("Error in sending, check your network")
				});
		}
	},
	mounted() {
		this.getGaleryList()
		let section = document.querySelectorAll('section');
		let lists = document.querySelectorAll('.list');
		function activeLink(li) {
			lists.forEach((item) => item.classList.remove('active'));
			li.classList.add('active');
		}
		lists.forEach((item) =>
			item.addEventListener('click', function () {
				activeLink(this);
			}));

		window.onscroll = () => {
			section.forEach(sec => {
				let top = window.scrollY;
				let offset = sec.offsetTop - 5;
				let height = sec.offsetHeight;
				let id = sec.getAttribute('id');

				if (top >= offset && top < offset + height) {
					const target = document.querySelector(`[href='#${id}']`).parentElement;
					activeLink(target);
				}
			})
		};

		document.getElementById("burger-container").addEventListener("click", () => {
			this.isMenuOpen = !this.isMenuOpen
		})
	}
}
</script>

<style lang="scss">
@import '../scss/globals.scss';

@media (max-width: 900px) {
	.mobileInactive {
		display: none !important;
	}
}

@media (min-width: 900px) {
	.desktopInactive {
		display: none !important;
	}
}

nav {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: #1b1b1b;
	border-bottom: 1px solid rgb(58, 58, 58);

	display: flex;
	justify-content: space-between;
	align-items: center;

	ul {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 15px;

		li {
			list-style-type: none;
			margin-left: 50px;
		}

		a {
			text-decoration: none;
			color: grey;
			font-weight: bold;
		}
	}

}

#mobileNav {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: #1b1b1b;
	border-bottom: 1px solid #3a3a3a;

	display: flex;
	justify-content: space-between;
	align-items: center;

	#burger-container {
		margin: 15px;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;



		.burger {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			flex-direction: column;
			height: 100%;

			animation: 0.4s to-burger forwards;

			@keyframes to-burger {
				from {
					opacity: 0%;
				}

				to {
					opacity: 100%;
				}
			}

			.line {
				height: 3px;
				border-radius: 2px;
				width: 40px;
				background-color: white;
			}
		}

		.openedBurger {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;

			animation: 0.4s to-cross-burger forwards;

			@keyframes to-cross-burger {
				from {
					opacity: 0%;
				}

				to {
					opacity: 100%;
				}
			}

			#cross-line1 {
				position: absolute;
				height: 3px;
				border-radius: 2px;
				width: 40px;
				background-color: white;

				animation: 0.4s to-cross-burger-line1 forwards;

				@keyframes to-cross-burger-line1 {
					from {
						transform: rotate(0deg);
					}

					to {
						transform: rotate(45deg);
					}
				}
			}

			#cross-line2 {
				position: absolute;
				height: 3px;
				border-radius: 2px;
				width: 40px;
				background-color: white;

				animation: 0.4s to-cross-burger-line2 forwards;

				@keyframes to-cross-burger-line2 {
					from {
						transform: rotate(0deg);
					}

					to {
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
}

#openedMenu {
	position: fixed;
	top: 0;
	left: 0;
	height: 105vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $main-bg;
	width: 100%;
	z-index: 9;


	li {
		list-style-type: none;
		font-size: 30px;
	}


	a {
		text-decoration: none;
		color: $text-dark;
		font-weight: bold;
	}
}

.active a {
	color: white;
}

#home {
	padding-top: 200px;

	#btn-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

#galery {
	background-color: $galery-bg;
	color: $text-light;
	padding: 100px 0;

	.video {
		width: 100%;
		height: 200px;
		border-radius: 5px;
		border: 1px solid #a8a8a8;
		background-color: #ffffff;
	}
}

#contact {
	padding: 100px 0;

	form {
		padding-top: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		input,
		textarea {
			width: 100%;
			padding: 15px;
			background-color: #2a2a2a;
			border: 1px solid #5a5a5a;
			border-radius: 5px;
			margin: 10px 0;
			font-size: 13px;
			color: $text-dark;

			margin &:focus {
				outline: 2px solid #0066ff71;
			}

		}

		#submit {
			border-color: yellow;
			font-weight: bold;
			animation: 0.4S submit-unhover forwards;

			&:hover {
				animation: 0.4s submit-hover forwards;
			}

			@keyframes submit-hover {
				from {
					background-color: #ffff0056;
					color: $text-dark;
				}

				to {
					background-color: #ffff00;
					color: $text-light;
				}
			}

			@keyframes submit-unhover {
				from {
					background-color: #ffff00;
					color: $text-light;
				}

				to {
					background-color: #ffff0056;
					color: $text-dark;
				}
			}
		}
	}
}

#footer {
	background-color: $galery-bg;
	color: $text-light;
	padding: 100px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	#content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		z-index: 10;

		span {
			margin-right: 10px;
			color: rgb(152, 152, 152);
		}
	}
}
</style>
