<template>
  <HomeView />
</template>
<script>
import HomeView from '@/views/HomeView.vue'
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyC2uThypaJLgCuyT16lSeIfleuFCjXl8pY",
  authDomain: "a2motion.firebaseapp.com",
  databaseURL: "https://a2motion-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "a2motion",
  storageBucket: "a2motion.appspot.com",
  messagingSenderId: "447971107495",
  appId: "1:447971107495:web:0c20fc4958a57a790b7182",
  measurementId: "G-Q5DN3JX00E"
};

initializeApp(firebaseConfig);

export default {
  components: {
    HomeView
  }
}
</script>
<style lang="scss">
@import './scss/globals.scss';

html {
  scroll-behavior: smooth;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-dark;
  background-color: $main-bg;
  font-family: system-ui;
}

.main-btn {
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #4B4B4B;
  background-color: #222222;
  color: $text-dark;
  width: 175px;
  font-size: small;
  height: 50px;
  animation: btn-unhover 0.4s forwards;

  &:hover {
    animation: btn-hover 0.4s forwards;
  }

  @keyframes btn-unhover {
    from {
      border: 1px solid white;
    }

    to {
      border: 1px solid #4B4B4B;
    }
  }

  @keyframes btn-hover {
    from {
      border: 1px solid #4B4B4B;
    }

    to {
      border: 1px solid white;
    }
  }
}
</style>
